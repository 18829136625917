export function addUAClass(){
  
  let UA = window.navigator.userAgent;
  let ua = UA.toLocaleLowerCase();
  
  let bodyElm = document.getElementsByTagName('body')[0];
  
  let MobileDetect = require('mobile-detect');
  let md = new MobileDetect(UA);
  
  let os;
  let mobile;
  
  if(md.mobile()){
    mobile = 'isSP';
    os = md.os().toLocaleLowerCase();
    bodyElm.classList.add(mobile,os);
  }else{
    bodyElm.classList.add('isPC');
  }
  
  if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
    bodyElm.classList.add('ie');
  } else if (ua.indexOf("edg") != -1 || ua.indexOf("edge") != -1) {
    bodyElm.classList.add('edge');
  } else if (ua.indexOf("opr") != -1 || ua.indexOf("opera") != -1) {
    bodyElm.classList.add('opera');
  } else if (ua.indexOf("chrome") != -1) {
    bodyElm.classList.add('chrome');
  } else if (ua.indexOf("safari") != -1) {
    bodyElm.classList.add('safari');
  } else if (ua.indexOf("firefox") != -1) {
    bodyElm.classList.add('firefox');
  } else if (ua.indexOf("opr") != -1 || ua.indexOf("opera") != -1) {
    bodyElm.classList.add('opera');
  }
}

