import * as func from './_function';

export function setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,bodyElmIdName){
  
  const DEBUG_MODE = true;
  
  let rootElm                   = document.getElementById(bodyElmIdName);
  let offCanvas                 = document.getElementById('offcanvas');
  let current_sc_offset_y            = window.pageYOffset;
  let beforScOffsetY;
  
  let elm_offcanvasBody = document.getElementById('offcanvasBody');
  
  let offcanvasHeaderH               = document.getElementById('offcanvasHeader').offsetHeight;
  let offcanvasBodyH                 = elm_offcanvasBody.offsetHeight;
  let offcanvasFooterH                 = document.getElementById('offcanvasFooter').offsetHeight;
  let flag_overflow_offcanvasStructure;
  const scrollFlagClassName = 'js_isOffcanvasBodyScroll';
  
  let offcanvasContainer01      = document.getElementById('offcanvasContainer01');
  const offcanvasHeaderBackBtn   = document.getElementById('headerOffcanvasBackBtn');
  const offcanvasFooterBackBtn   = document.getElementById('offcanvasFooterBackBtn');
  const slideOutActiveCLassName    = 'js_isSideout';
  const slideOutAnimationDelay        = 300;
  const container02ActiveClassName     = 'js_isShown';
  
  let currentOffacanvasMenuDepth = 0;
  
  // ------------------------------------------------------------
  // オフキャンバスの高さを計算
  // ------------------------------------------------------------
  
  function debug_msg01(){
    if(DEBUG_MODE){
      let calc = offcanvasHeaderH + offcanvasBodyH;
      let winH = window.innerHeight;
      console.log('------------DEBUG------------');
      console.log('offcanvasBodyの高さ：' + offcanvasBodyH);
      console.log('windowの高さ：' + winH);
      console.log('offcanvas内部のコンテンツの合計：' + calc);
      console.log('-----------------------------');
      
    }
  }
  
  // ------------------------------------------------------------
  // オフキャンバスコンテンツがウィンドウの高さを超過していないかチェック
  // ------------------------------------------------------------
  
  function checkOffcanvasContentsOverflow(){
    
    offcanvasBodyH  = document.getElementById('offcanvasBody').offsetHeight;
    let offcanvasStructureAllH = offcanvasHeaderH + offcanvasBodyH;
    console.log(offcanvasBodyH);
    
    // オフキャンバスコンテンツの合計がウィンドウの高さを超過しているかチェック
    // -------------------
    flag_overflow_offcanvasStructure = offcanvasStructureAllH >= window.innerHeight;
    
    if(DEBUG_MODE){
      console.log('フラグ：checkOffcanvasContentsOverflow = ' + flag_overflow_offcanvasStructure);
    }
  }
  
  // ------------------------------------------------------------
  // offcanvasBody（コンテンツが入る領域）の高さの上限値設定
  // ------------------------------------------------------------
  
  function setOffcanvasBodyMaxHeight(){
    if(flag_overflow_offcanvasStructure){
      let calcOffcanvasBodyH = window.innerHeight - offcanvasHeaderH - offcanvasFooterH;
      elm_offcanvasBody.style.maxHeight = calcOffcanvasBodyH + 'px';
      offCanvas.classList.add(scrollFlagClassName);
      toggleoffcanvasScrollableNav(true);
      
      if(DEBUG_MODE){
        console.log('offcanvasBodyの高さ上限設定が処理されました');
      }
      
    }else{
      if(DEBUG_MODE){
        console.log('offcanvasBodyの高さ上限設定が解除されました');
        elm_offcanvasBody.style.maxHeight = 'none';
        offCanvas.classList.remove(scrollFlagClassName);
        toggleoffcanvasScrollableNav(false);
      }
    }
  }
  
  // ------------------------------------------------------------
  // スクロールを催すナビゲーションの表示非表示処理
  // ------------------------------------------------------------
  
  function toggleoffcanvasScrollableNav(bool){
    let elm = document.getElementById('offcanvasScrollableNav');
    if(bool){
      elm.classList.add('js_isActive');
    }else{
      elm.classList.remove('js_isActive');
    }
    
  }
  
  // ------------------------------------------------------------
  // オフキャンバスメイン開閉処理
  // ------------------------------------------------------------
  
  function toggleOffcanvas(){
    if (rootElm.classList.contains('js_isOffcanvasActive')) {
      
      //閉じる場合の処理
      rootElm.classList.remove('js_isOffcanvasActive');
      setBodyFixed(false);
      currentOffacanvasMenuDepth = 0; //現在いる階層を0に
      
      
    } else {
      
      //開く場合の処理
      rootElm.classList.add('js_isOffcanvasActive');
      setBodyFixed(true);
      currentOffacanvasMenuDepth = 1; //現在いる階層を1に
    }
  }
  
  // ------------------------------------------------------------
  // オフキャンバスコンテナとUI周りのリセット処理
  // ------------------------------------------------------------
  
  function resetOffcanvasAllContainer(){
    if (offcanvasContainer01.classList.contains(slideOutActiveCLassName)) {
      
      setTimeout(function () {
        
        // コンテナ01のアクティブクラスを解除
        // -------------------
        offcanvasContainer01.classList.remove(slideOutActiveCLassName);
        
        // コンテナ02のアクティブクラスを解除し、コンテナ01の非表示設定を解除
        // -------------------
        document.querySelectorAll(".offcanvasContainer02").forEach(function (offcanvasContainer02) {
          if (offcanvasContainer02.classList.contains(container02ActiveClassName)) {
            offcanvasContainer02.classList.remove(container02ActiveClassName);
            offcanvasContainer01.style.display = "block";
          }
          
        });
        
        // バックボタンを削除
        // -------------------
        offcanvasHeaderBackBtn.classList.remove('js_isActive');
        offcanvasFooterBackBtn.classList.remove('js_isActive');
        
      }, slideOutAnimationDelay);
    }
  }
  
  // ------------------------------------------------------------
  // ヘッダートリガクリック処理
  // ------------------------------------------------------------
  
  document.getElementById('offcanvasTrigger').addEventListener('click', () => {
    
    // -------------------
    // 高さ保持関係関数発火
    // -------------------
    checkOffcanvasContentsOverflow();
    setOffcanvasBodyMaxHeight();
    debug_msg01();
    
    // -------------------
    // オフキャンバス表示非表示制御処理 + body要素固定処理
    // -------------------
    toggleOffcanvas();
    
    // -------------------
    // トリガークリック時にスライドアウト処理・バックボタンをリセットする
    // -------------------
    resetOffcanvasAllContainer();
      
  });
  
  
  // ============================================================
  // コンテナ01のプラスボタンを押下した際の処理
  // ============================================================
  
  const offcanvasPrimaryNavItemTriggers = document.querySelectorAll(".offcanvasPrimaryNavItemTrigger");
  let activeSecondaryContainer; //現在アクティブステータスの第二階層オフキャンバスコンテナが格納される
  
  offcanvasPrimaryNavItemTriggers.forEach(function(elm){
    
    elm.addEventListener("click",function(){
      
      currentOffacanvasMenuDepth = 2; //現在いる階層を2に
      
      // ------------------------------------------------------------
      // 親コンテナ(container01)に対して非表示処理を行う
      // ------------------------------------------------------------
      offcanvasContainer01.classList.add(slideOutActiveCLassName);
      
      setTimeout(function(){
        offcanvasContainer01.style.display = "none";
      },300);
      
      // ------------------------------------------------------------
      // 下階層に行く際のクリックイベント処理とアニメーション
      // ------------------------------------------------------------
      let targetContainer = this.dataset.targetContainer;
      document.querySelectorAll("." + targetContainer).forEach(function (elm){
        
        activeSecondaryContainer = elm;
        
        setTimeout(function(){
          
          elm.classList.add(container02ActiveClassName);
          
          $.when(
            
            elm.style.display = "block",
            offcanvasFooterBackBtn.style.opacity = 0,
            offcanvasFooterBackBtn.style.display = "block"
            
          ).done(function() {
            
            checkOffcanvasContentsOverflow();
            setOffcanvasBodyMaxHeight();
            debug_msg01();
            
            elm.animate(
              [
                {opacity: '0'},
                {
                  opacity: '1',
                  display: 'none'
                }
              ],
              {duration: slideOutAnimationDelay}
            ).onfinish = () => {
              
              // バックボタンフェードイン処理
              // -------------------
              // FIXME : 冗長な処理なので手が空いた時に再コーディング（担当：阪西）
              offcanvasHeaderBackBtn.classList.add('js_isActive');
              offcanvasFooterBackBtn.classList.add('js_isActive');
            };
            
          });
        },300);
        
      });
      
    });
  });
  
  // ============================================================
  //
  // ============================================================
  
  function toggleOffanvasBackControler(bool){
    if(bool){
      
      $.when(
        offcanvasFooterBackBtn.style.display = "block",
        offcanvasHeaderBackBtn.style.display = "block",
        offcanvasFooterBackBtn.style.opacity = 0,
        offcanvasFooterBackBtn.style.opacity = 0
      ).done(function(){
      
      });
      
    }
  }
  toggleOffanvasBackControler();
  
  // ============================================================
  // バックボタン押下時処理
  // ============================================================
  
  function setOffcanvasFooterBackBtn(elm){

    elm.addEventListener('click', () => {
      
      console.log('c');

      setTimeout(function(){

        // コンテナ処理
        // -------------------
        activeSecondaryContainer.animate(
          [
            {opacity:'1'},
            {
              opacity:'0',
            }
          ],
          {duration:slideOutAnimationDelay}
        ).onfinish = () => {
          
          $.when(
            activeSecondaryContainer.classList.remove(container02ActiveClassName),
            offcanvasFooterBackBtn.style.display = "none",
            activeSecondaryContainer.style.display = 'none'
            
          ).done(function (){
            offcanvasContainer01.style.display = 'block';
              checkOffcanvasContentsOverflow();
              setOffcanvasBodyMaxHeight();
              debug_msg01();
          });
          

          setTimeout(function(){
            offcanvasContainer01.classList.remove(slideOutActiveCLassName);
          },100);

        };

        // バックボタン処理
        // -------------------

        // FIXME : 冗長な処理なので手が空いた時に再コーディング（担当：阪西）

        function backBtnFeedout(_elm){
          _elm.animate(
            [
              {opacity:'1'},
              {opacity:'0'}
            ],
            {duration:slideOutAnimationDelay}
          ).onfinish = () => {
            _elm.classList.remove('js_isActive')
          };
        }
        backBtnFeedout(offcanvasHeaderBackBtn);
        //backBtnFeedout(offcanvasFooterBackBtn);

      },100);

    });

  }
  
  setOffcanvasFooterBackBtn(offcanvasHeaderBackBtn);
  setOffcanvasFooterBackBtn(offcanvasFooterBackBtn);
  
  // ============================================================
  // 背景body要素要素処理
  // ============================================================
  
  window.addEventListener('scroll', () => {
    current_sc_offset_y  = window.pageYOffset;
  })
  
  function setBodyFixed(mode){
    if(mode === true){
      beforScOffsetY = current_sc_offset_y;
      rootElm.style.position = "fixed";
      document.body.style.top = current_sc_offset_y * -1 + 'px';
    }else{
      rootElm.style.position = '';
      document.body.style.top = ''
      window.scrollTo(0, beforScOffsetY)
    }
  }
  
 
  
  
}


