// モジュール読み込み
// ============================================================

//import Vue from 'vue';
import 'mobile-detect';
import * as func from '../../../../functions/_function';                        //関数関係
import * as offcanvas from '../../../../functions/_offcanvas';                  //オフキャンバス //デバイス判定・ブラウザ判定ライブラリ
import * as checkUA from '../../../../functions/_checkUA';
import Modal from '../../../../../../node_modules/bootstrap/js/dist/modal.js';//デバイス判定・ブラウザ判定処理
import * as LazyLoad from '../../../../../../node_modules/lazyload/lazyload.js';

// splide
// -------------------
import '@splidejs/splide/css';
import '@splidejs/splide/css/skyblue';
import Splide from '@splidejs/splide';
import  {AutoScroll}  from '@splidejs/splide-extension-auto-scroll';

// GSAP
// -------------------
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import {initLoading} from "../../../../functions/_loading";
import {addClass} from "@splidejs/splide/src/js/utils";
gsap.registerPlugin(ScrollTrigger,MotionPathPlugin);


// ============================================================
// 定数
// ============================================================

const BLAKEPOINT_HEADER_SP_MODE = 1024;                               //ヘッダーレスポンシブブレイクポイント
const DEBUG_MODE                = false;


// ============================================================
// グローバル変数
// ============================================================

let CURRENT_SCROLL_POS = 0;
let WIN_WIDTH                 = window.innerWidth;
let WIN_HEIGHT                = window.innerHeight;
let CURRENT_SC_OFFSET_X       = window.pageXOffset;
let CURRENT_SC_OFFSET_Y       = window.pageYOffset;
let SCROLLDOWN_FLAG           = true;
let HEADER_H                  = document.getElementById('globalHeader').offsetHeight;

// ============================================================
// サイト内共通処理
// ============================================================

// 内容：スムーススクロール処理関数
// ------------------------------------------------------------
function smoothScroll(){
  document.querySelectorAll('.js_smoothScrollTrigger').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
      e.preventDefault();
      
      const targetId = this.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      
      window.scrollTo({
        top: targetElement.offsetTop - HEADER_H - 20,
        behavior: 'smooth'
      });
    });
  });
}


// ============================================================
// サイト共通関数初期化
// ============================================================

//animation.scrollObserver();                                              // アニメーション処理
checkUA.addUAClass();                                                    // UAおよびデバイスクラス追加処理
//headerFixed.setHeaderFixed();                                          // ヘッダー固定
//smoothScroll.setSmoothScroll();                                        // スムーススクロール
//bliderBox.setBliderBox();                                              // ブラインダーボックス初期化
offcanvas.setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,'wrapper');  // オフキャンバス処理
lazyload(
  document.querySelectorAll('.lazyload')
); 　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　//画像遅延読み込み
// イベント毎のサイト共通関数初期化
// ============================================================

window.addEventListener('scroll', () => {
  CURRENT_SC_OFFSET_X = window.pageXOffset;
  CURRENT_SC_OFFSET_Y = window.pageYOffset;
  doScrollDirectionFlexibleElementFixed();
  
  //引数：固定,固定,ボタンを出現させるきっかけとなる要素のID名,ボタンを固定させるきっかけとなる要素のID名
  //pagetop.setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,'mv','footer');
  
})

window.addEventListener('resize', () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
  HEADER_H = document.getElementById('globalHeader').offsetHeight;
  updateTargetMvHeight();
})

// ============================================================
// 汎用関数：
// ============================================================

function doFullwidthSlider01(){
  // splideスライダー設定
  // TODO ブレイクポイントでスライドの速さを変える
  
  if(document.querySelector('.fullwidthSlider01')){
    let ctx = new Splide( '.fullwidthSlider01',{
      autoScroll :  {
        speed : 0.3,
        autoStart: true,
      },
      type : 'loop',
      perPage: 1,
      arrows:false,
      pagination:false,
      pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
    } );
    ctx.mount({AutoScroll});
  }
}

function doTypegraphyDrowingLinerAnimation(){
  gsap.fromTo(".gsap_svgAnimation",
    {
      'stroke-dashoffset': '1300px'
    },
    {
      'stroke-dashoffset': '0',
      duration: 2,
      stagger:0.3,
      scrollTrigger:{
        trigger:'.gsap_animationTrigger',
        start:'top bottom',
        end:'bottom top',
        // scrub:true,
        debug:true
      },
    },
  );
}


// ============================================================
// フォーム共通関数
// ============================================================

// TODO:hanada:処理内容を記載
// ------------------------------------------------------------

function setFieldAccessibility(){
  // フォーカスイベントを処理する関数
  function handleFocus(event) {
    // 現在のplaceholderをlocal storageに保存
    localStorage.setItem(event.target.id + '-placeholder', event.target.placeholder);
    // placeholderを'入力中…'に変更
    event.target.placeholder = '入力中…';
  }
  
  // ブラーイベントを処理する関数
  function handleBlur(event) {
    // local storageから元のplaceholderを取得
    const originalPlaceholder = localStorage.getItem(event.target.id + '-placeholder');
    // 元のplaceholderに戻す
    event.target.placeholder = originalPlaceholder;
  }
  
  // クラス 'focus-change' を持つ全ての要素を取得
  const focusChangeElements = document.querySelectorAll('.focus-change');
  
  // 各要素にフォーカスとブラーのイベントリスナーを追加
  focusChangeElements.forEach(function(element) {
    // 各要素に一意のIDが設定されていない場合はランダムな一意のIDを生成して設定
    if (!element.id) {
      element.id = 'focus-change-' + Math.random().toString(36).substr(2, 9);
    }
    
    element.addEventListener('focus', handleFocus);
    element.addEventListener('blur', handleBlur);
  });
}


// ------------------------------------------------------------
// 関数名：confirmedField
//
// TODO:hanada:処理内容を記載
// ------------------------------------------------------------

function confirmedField(){
  // #confirmed要素を取得
  const confirmedElement = document.getElementById('confirmed');
  
  if (confirmedElement) {
    // confirmed要素の全ての子要素を取得
    const fields = confirmedElement.querySelectorAll('input, textarea, select, button');
    
    // 各子要素に対して処理を適用
    fields.forEach(function(field) {
      // 'disabled'属性を適用
      field.disabled = true;
      
      // 値が入力されていない場合は'placeholder'を空にする
      if ((field.tagName === 'INPUT' || field.tagName === 'TEXTAREA') && field.value === '') {
        field.placeholder = '';
      }
    });
  }
}


// ============================================================
// トップページ固有処理
// ============================================================

function doTopScript() {
  
  // ============================================================
  // splide animation
  // ============================================================
  
  if (document.querySelector('.top_mv')) {
    var top_mv = new Splide('.mv_slider', {
      autoplay: true,
      type: 'fade',
      rewind: true,
      rewindSpeed: 2000,
      perPage: 1,
      pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
      pauseOnFocus: false, // 矢印をクリックしてもスクロールを停止させない
      arrows: false,
      height: '100vh',
      pagination: false,
      interval: 5000, // 自動再生の間隔
      speed: 1500, // スライダーの移動時間
    })
    top_mv.mount();
  }
  if (document.querySelector('.splide_top_newsHeadline')) {
    var newsHeadline_splide = new Splide('.splide_top_newsHeadline', {
      type: 'loop',
      perPage: 1,
      arrows: false,
      autoplay: true,
      rewind: true,
      // rewindByDrag: true,
      classes: {
        pagination: 'splide__pagination headline01_pagination',
        page: 'splide__pagination__page headline01_page',
      },
    });
    newsHeadline_splide.mount();
  }
  if (document.querySelector('.splide_top_worksSummary')) {
    var worksSummary_splide = new Splide('.splide_top_worksSummary', {
      type: 'loop',
      arrows: false,
      perPage: 1,
      padding: '5rem',
      focus: 'center',
      gap: '10px',
      rewind: true,
      autoplay: true,
      mediaQuery: "min",
      classes: {
        pagination: 'splide__pagination entrySummary01_pagination',
        page: 'splide__pagination__page entrySummary01_page',
      },
      breakpoints: {
        980: {
          // perPage: 1,
          // padding :'0rem',
          // focus:'left',
        },
      },
    });
    worksSummary_splide.mount();
  }

  
  // ============================================================
  // GASP animaiton
  // ============================================================
  
  // ------------------------------------------------------------
  // メインビジュアル キャッチコピー表示アニメーション
  // ------------------------------------------------------------
  
  function doMvCatchcopyAnimation(){
    const tl = gsap.timeline({ repeat: 1, repeatDelay: 0.5 });
    
    document.querySelectorAll(".word").forEach((word) => {
      tl.add(createChildTimeline(word), "-=90%");
    });
    function createChildTimeline(element) {
      const elText = element.querySelector(".rect");
      const tl = gsap
        .timeline()
        .from(element, {
          y: 16,
          opacity: 0,
          duration: 0.75,
          stagger:2,
          ease: "power4.out",
        })
        .set(elText, { opacity: 0 })
        .to(
          elText,
          {
            x: "105%",
            duration: 1,
            stagger:2,
            ease: "power4.out",
          },
          "-=50%",
        );
      return tl;
    }
  }
  
  function doSlideInImage_sectionAbout(){
    gsap.fromTo(".gsap_decorateImg" ,{
        x:60,
        autoAlpha : 0
      },
      {
        x:0,
        autoAlpha : 1,
        duration  : 1.5,
        ease:"power4.outIn",
        scrollTrigger:{
          trigger:'.gsap_decorateImg_target',
          start:'top center',
          end:'bottom top',
          // scrub:true,
          debug:true
        },
      }
    );
  }
  
  doMvCatchcopyAnimation();
  doSlideInImage_sectionAbout();
}


// ============================================================
// スクロールによって表示非表示を切り替える処理
// ============================================================
const elm_header = document.getElementById('globalHeader');
const elm_modal = document.getElementById('flexibleModal')
const targetMv = document.querySelector(".js_target_mv");
let lastScrollTop = 0;
let targetMvHeight;

updateTargetMvHeight();

function updateTargetMvHeight() {
  if(targetMv){
    targetMvHeight = targetMv.offsetHeight;
  }
}

function doScrollDirectionFlexibleElementFixed() {
  
  const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
  if (currentScrollTop < targetMvHeight) {
    // .js_target_mvを超えていない時の処理：特に何もしない
    return;
  }
  
  if(document.querySelectorAll('#globalHeader').length){
    if (currentScrollTop > lastScrollTop) {
      // 下スクロール
      elm_header.classList.add("moveOut");
      elm_header.classList.remove("moveIn")
      
    } else {
      // 上スクロール
      elm_header.classList.add("moveIn");
      elm_header.classList.remove("moveOut");
    }
  }
  

  if(document.querySelectorAll('#flexibleModal').length){
    if (currentScrollTop > lastScrollTop) {
      // 下スクロール
      
      elm_modal.classList.add("moveOut");
      elm_modal.classList.remove("moveIn");
      
    } else {
      // 上スクロール
      elm_modal.classList.add("moveIn");
      elm_modal.classList.remove("moveOut");
    }
  }

  lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
  updateTargetMvHeight();
}

// ============================================================
// modal
// ============================================================

function cta02ModalActive() {
  if(document.querySelectorAll('.js_target_modal').length){
    const cta02ModalAlternative = new Modal('#cta02', {
       backdrop: true,
    })
    document.querySelector('a[data-bs-toggle="modal"]').addEventListener('click',cta02ModalAlternative);
  }
  
  
}

function doScrollDirectionFlexibleModalFixed(){
  const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
  if (currentScrollTop < targetMvHeight) {
    // .js_target_mvを超えていない時の処理：特に何もしない
    return;
  }
  
  if (currentScrollTop > lastScrollTop) {
    // 下スクロール
    elm_modal.classList.add("moveOut");
    elm_modal.classList.remove("moveIn");
  } else {
    // 上スクロール
    elm_modal.classList.add("moveIn");
    elm_modal.classList.remove("moveOut");
  }
  
  lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
  updateTargetMvHeight();
}
// ============================================================
// 読み込み時処理
// ============================================================

window.addEventListener('DOMContentLoaded',() => {
  
  // ------------------------------------------------------------
  // 全ページ共通読み込み
  // ------------------------------------------------------------
  
  smoothScroll();
  
  // ------------------------------------------------------------
  // 固有・条件読み込み
  // ------------------------------------------------------------
  
  if(document.querySelectorAll('.top').length) {
    doTopScript();
    doTypegraphyDrowingLinerAnimation();
    initLoading();
    cta02ModalActive();
    doScrollDirectionFlexibleModalFixed();
  }
  
  if(document.querySelectorAll('.company').length) {
    console.log("company")
    doTypegraphyDrowingLinerAnimation();
    if (document.querySelector('.splide_company_worksSummary')) {
      var worksSummary_splide = new Splide('.splide_company_worksSummary', {
        type: 'loop',
        arrows: false,
        perPage: 1,
        padding: '5rem',
        focus: 'center',
        gap: '10px',
        rewind: true,
        autoplay: true,
        mediaQuery: "min",
        classes: {
          pagination: 'splide__pagination entrySummary01_pagination',
          page: 'splide__pagination__page entrySummary01_page',
        },
        breakpoints: {
          980: {
            // perPage: 1,
            // padding :'0rem',
            // focus:'left',
          },
        },
      });
      console.log("Splide")
      worksSummary_splide.mount();
    }
  }
  
  if(document.querySelectorAll('.fullwidthSlider01').length) {
    doFullwidthSlider01();
  }
  
  if(document.querySelectorAll('.form_control').length) {
    confirmedField();
    setFieldAccessibility();
  }
  
})

// ============================================================








// ============================================================
// バリデーション
// ============================================================
//
// $(function (){
//
//   if($('#hostingCheckForm').length){
//     $(".js_v-required").each(function() {
//       $(this).rules("add", {
//         required: true,
//         messages: {
//           required: "この入力項目は必須です",
//         }
//       });
//     });
//     $(".js_v-number").each(function() {
//       $(this).rules("add", {
//         required: true,
//         number: true,
//         messages: {
//           required: "この入力項目は必須です",
//           number: "半角もしくは全角の数字で入力してください"
//         }
//       });
//     });
//     $(".js_v-email").each(function() {
//       $(this).rules("add", {
//         required: true,
//         email: true,
//         messages: {
//           required: "この入力項目は必須です",
//           email: "有効なメールアドレスを入力してください"
//         }
//       });
//     });
//   }
//   $('#hostingCheckForm').validate({
//
//     errorElement: "span",
//
//     errorPlacement: function(error, element) {
//       element.next(".js_v-error_massage").remove();
//
//       if (error.text()) {
//         var errorWrapper = $("<div class='js_v-error_massage'></div>").append(error);
//         error.addClass("v-massage_text");
//         errorWrapper.insertAfter(element);
//       }
//
//     },
//     success: function(label, element) {
//       // エラーメッセージのクリア
//       $(element).next(".js_v-error_massage").remove();
//     },
//   });
//
//
// });